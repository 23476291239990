import React from 'react';
import Icon from '@/components/npl/Icon';

function IconWithBackgroundOutline({ name }) {
  return (
    <div className="flex h-40 w-40 shrink-0 items-center justify-center rounded-8 border-1 border-npl-neutral-light-solid-6 bg-white-default">
      <Icon width="20" height="20" name={name} fill="#1B1B1880" />
    </div>
  );
}

export default IconWithBackgroundOutline;
