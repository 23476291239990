import MobileBottomPopup from '@/components/common/MobileBottomPopup';
import TimezoneHeadlessSelect from '@/components/common/TimezoneHeadlessSelect/TimezoneHeadlessSelect';
import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';
import { formatSlotTime } from '@/features/1on1Sessions/utils';
import { t } from '@/utility/localization';
import classNames from 'classnames';
import { format, parse } from 'date-fns';
import React from 'react';

const getFormattedSelectedDate = (selectedSlot) => {
  const slotDate = parse(selectedSlot.date, 'dd/MM/yyyy', new Date());
  const selectedDateFormatted = slotDate
    ? format(slotDate, 'iii, d MMM')
    : '';

  return selectedDateFormatted;
};

const SelectSpotPopup = ({
  open,
  selectedSlot,
  selectedSpot,
  onSpotSelection,
  selectedTimeZoneId,
  setSelectedTimeZoneId,
  onBack,
  onBookSlotClick,
  onClose
}) => {
  const selectedDateFormatted = selectedSlot
    ? getFormattedSelectedDate(selectedSlot)
    : '';

  return (
    <div>
      <MobileBottomPopup open={open} onClose={onClose}>
        <div className="relative h-full pb-[80px] ">
          {/* Header */}
          <div className="flex items-center justify-between space-x-8 border-b-1 border-npl-neutral-light-solid-4 px-16 py-12">
            {/* Back button */}
            <button
              className="flex h-40 w-40 items-center justify-center"
              onClick={onBack}>
              <Icon
                name="arrow-left"
                width={16}
                height={16}
                fill="#1B1B18A6"
              />
            </button>
            {/* Title */}
            <h3 className="text-label-lg font-semibold">
              {t('select-a-time')}
            </h3>
            {/* Close button */}
            <button
              className="flex h-40 w-40 items-center justify-center"
              onClick={onBack}>
              <Icon
                name="x-close"
                width={16}
                height={16}
                fill="#1B1B18A6"
              />
            </button>
          </div>

          <div className="h-full overflow-scroll px-16 pb-[80px] pt-24">
            {/* Pick a time zone */}
            <TimezoneHeadlessSelect
              value={selectedTimeZoneId}
              onChange={setSelectedTimeZoneId}
            />

            {/* Selected Date */}
            <h4 className="mt-16 text-label-lg font-medium text-black">
              {selectedDateFormatted}
            </h4>
            {/* List of available times */}
            <div className="mt-12 flex flex-col space-y-12">
              {selectedSlot?.spots?.map((spot, key) => {
                const isSelected =
                  selectedSpot?.start_time &&
                  selectedSpot?.start_time === spot?.start_time;

                return (
                  <div
                    key={key}
                    role="button"
                    tabIndex={0}
                    onClick={() => onSpotSelection(spot)}
                    className={classNames(
                      'flex items-center justify-center rounded-8 border-1 px-20 py-12',
                      {
                        'border-npl-yellow-light-solid-9 bg-npl-yellow-light-solid-3':
                          isSelected,
                        'border-npl-neutral-light-solid-6 bg-white-default':
                          !isSelected
                      }
                    )}>
                    <p className="text-center text-label-lg font-medium text-dark-1b">
                      {formatSlotTime(spot?.start_time)}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Button */}
          <div className="absolute bottom-0 left-0 right-0 bg-white-default">
            <div className="flex justify-center p-16">
              <NPLButton
                stretch
                hierarchy="accent_primary"
                buttonText={t('book')}
                onClick={onBookSlotClick}
              />
            </div>
          </div>
        </div>
      </MobileBottomPopup>
    </div>
  );
};

export default SelectSpotPopup;
