import { t } from '@/utility/localization';
import Icon from '@/components/npl/Icon';

const NotAcceptingBookingsCard = ({ cmName }) => {
  return (
    <div className="space-y-24 rounded-28 bg-npl-base-white p-32 shadow-npl-styles-shadow-01">
      <Icon
        name="message-x-square"
        width={40}
        height={40}
        className="fill-npl-text-icon-on-light-surface-tertiary"
      />
      <div className="text-label-lg font-medium text-npl-text-icon-on-light-surface-secondary">
        {t('cm-not-accepting-bookings', { cmName: cmName })}
      </div>
    </div>
  );
};

export default NotAcceptingBookingsCard;
