import React from 'react';
import IconWithBackgroundOutline from '../IconWithBackgroundOutline/IconWithBackgroundOutline';

function IconBlockWithText({ items }) {
  return (
    <div className="c-IconBlockWithText flex flex-col space-y-16">
      {items.map((item, index) => (
        <div key={index}>
          <div className="flex space-x-16">
            {/* Icon */}
            <IconWithBackgroundOutline name={item.icon} />
            <div className="flex flex-col justify-center">
              <p className="text-label-md font-medium text-dark-1b">
                {item.title}
              </p>
              {item.description && (
                <p className="mt-4 text-label-md text-dark-1b text-opacity-65">
                  {item.description}
                </p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default IconBlockWithText;
