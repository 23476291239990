import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import useQueryParams from '@/hooks/useQueryParams';

const DynamicWelcomeToCommunitySplash = dynamic(
  () =>
    import(
      '@/components/features/CommunityPage/components/home/WelcomeToCommunitySplash'
    )
);

const WithWelcomeToCommunitySplashScreen = ({
  communityInfo,
  open = false
}) => {
  const [showWelcomeSplashScreen, setShowWelcomeSplashScreen] =
    useState(false);
  const router = useRouter();
  const pathname = usePathname();

  const { currentRouterQuery } = useQueryParams();

  useEffect(() => {
    const showWelcomeSplashScreen =
      !!currentRouterQuery?.showSignupSuccessPrompt;

    // open prop is for free community signup flow
    // so that this screen appears instantly instead of relying on query params

    // this is for other flows where we must remove showSignupSuccessPrompt from the URL
    if (showWelcomeSplashScreen || open) {
      setShowWelcomeSplashScreen(true);
      if (currentRouterQuery?.showSignupSuccessPrompt) {
        setTimeout(() => {
          // Remove showSignupSuccessPrompt from the URL
          const newQuery = { ...currentRouterQuery };
          delete newQuery.showSignupSuccessPrompt;

          router.replace({
            pathname: pathname,
            query: newQuery
          });
        }, 3500);
      }
    }
  }, [currentRouterQuery?.showSignupSuccessPrompt, pathname, open]);

  return showWelcomeSplashScreen ? (
    <DynamicWelcomeToCommunitySplash communityInfo={communityInfo} />
  ) : null;
};

export default WithWelcomeToCommunitySplashScreen;
