import { useEffect, useMemo } from 'react';
import {
  ONE_ON_ONE_LANDING_PAGE_BOOKING_CARD_BOOK_BTN_CLICK,
  ONE_ON_ONE_LANDING_PAGE_BOOKING_CARD_CHANGE_TZ,
  ONE_ON_ONE_LANDING_PAGE_JOIN_BTN_CLICK,
  ONE_ON_ONE_LANDING_PAGE_BOOKING_DAYS_CHANGE_DATE,
  ONE_ON_ONE_LANDING_PAGE_BOOKING_DAYS_CHANGE_TIME,
  ONE_ON_ONE_LANDING_PAGE_BOOKING_DAYS_LEFT_NAV_ARROW_CLICK,
  ONE_ON_ONE_LANDING_PAGE_BOOKING_DAYS_RIGHT_NAV_ARROW_CLICK,
  ONE_ON_ONE_LANDING_PAGE_VIEW_EVENT,
  ONE_ON_ONE_LANDING_PAGE_VIEW_DATES_CLICK
} from '../analyticsUtils';
import { trackGAEvent } from '@/utility/analytics';

const useOneOnOneLandingPageAnalytics = ({
  communityInfo,
  resourceInfo
}) => {
  const commonAnalyticsParams = useMemo(() => {
    return {
      communityId: communityInfo?._id,
      communityCode: communityInfo?.code,
      sessionId: resourceInfo?._id,
      sessionTitle: resourceInfo?.title
    };
  }, [
    communityInfo?._id,
    communityInfo?.code,
    resourceInfo?._id,
    resourceInfo?.title
  ]);

  const trackLeftNavArrowClickOnBookingDays = () => {
    trackGAEvent(
      ONE_ON_ONE_LANDING_PAGE_BOOKING_DAYS_LEFT_NAV_ARROW_CLICK,
      commonAnalyticsParams
    );
  };

  const trackRightNavArrowClickOnBookingDays = () => {
    trackGAEvent(
      ONE_ON_ONE_LANDING_PAGE_BOOKING_DAYS_RIGHT_NAV_ARROW_CLICK,
      commonAnalyticsParams
    );
  };

  const trackChangeBookingDate = ({ slotData, position }) => {
    trackGAEvent(ONE_ON_ONE_LANDING_PAGE_BOOKING_DAYS_CHANGE_DATE, {
      ...commonAnalyticsParams,
      slotDate: slotData?.date,
      slotDay: slotData?.day,
      position
    });
  };

  const trackChangeBookingTime = ({ slotSpot }) => {
    trackGAEvent(ONE_ON_ONE_LANDING_PAGE_BOOKING_DAYS_CHANGE_TIME, {
      ...commonAnalyticsParams,
      startTime: slotSpot?.start_time
    });
  };

  const trackChangeTimezone = ({ timezoneId }) => {
    trackGAEvent(ONE_ON_ONE_LANDING_PAGE_BOOKING_CARD_CHANGE_TZ, {
      ...commonAnalyticsParams,
      timezoneId
    });
  };

  const trackBookBtnClick = ({ slotData, slotSpotData, timezone }) => {
    trackGAEvent(ONE_ON_ONE_LANDING_PAGE_BOOKING_CARD_BOOK_BTN_CLICK, {
      ...commonAnalyticsParams,
      slotDate: slotData?.date,
      slotDay: slotData?.day,
      startTime: slotSpotData?.start_time,
      timezone
    });
  };

  const trackJoinBtnClick = () => {
    trackGAEvent(
      ONE_ON_ONE_LANDING_PAGE_JOIN_BTN_CLICK,
      commonAnalyticsParams
    );
  };

  const trackMobileViewDatesClick = () => {
    trackGAEvent(
      ONE_ON_ONE_LANDING_PAGE_VIEW_DATES_CLICK,
      commonAnalyticsParams
    );
  };

  // Track One on One Landing Page view
  useEffect(() => {
    trackGAEvent(
      ONE_ON_ONE_LANDING_PAGE_VIEW_EVENT,
      commonAnalyticsParams
    );
  }, [commonAnalyticsParams]);

  return {
    trackLeftNavArrowClickOnBookingDays,
    trackRightNavArrowClickOnBookingDays,
    trackChangeBookingDate,
    trackChangeBookingTime,
    trackChangeTimezone,
    trackBookBtnClick,
    trackJoinBtnClick,
    trackMobileViewDatesClick
  };
};

export default useOneOnOneLandingPageAnalytics;
