import React from 'react';
import HeadlessSelect from '../Form/HeadlessSelect/HeadlessSelect';
import { getTimeZoneOptions } from '@/features/1on1Sessions/constants';
import NPLButton from '@/components/npl/NPLButton';
import classNames from 'classnames';
import Icon from '@/components/npl/Icon';
import { t } from '@/utility/localization';

function TimezoneHeadlessSelect({ value, onChange, isTextLinkButton }) {
  return (
    <HeadlessSelect
      options={getTimeZoneOptions()}
      value={value}
      onChange={onChange}
      placement="bottom-start"
      dropdownClassNames="!max-h-[40vh] w-[381px]"
      showSearch
      headerText={t('select-a-time-zone')}
      renderField={({ option }) => (
        <NPLButton
          hierarchy={isTextLinkButton ? 'plain' : 'outline'}
          isSecondary
          buttonText={option?.label}
          leadIcon="globe-02"
          size="sm"
        />
      )}
      renderOption={({ option, value, handleOptionClick }) => {
        const selectedValue = value === option?.value;
        return (
          <div
            role="button"
            tabIndex={0}
            onClick={() => handleOptionClick(option)}
            className={classNames(
              'mx-8 rounded-8 px-8 py-12 font-normal text-neutral-10 hover:bg-neutral-97 transition-all',
              selectedValue ? 'bg-neutral-95' : ''
            )}>
            <div className="flex items-center justify-between">
              <div>{option.label}</div>
              {selectedValue && (
                <Icon name="check" width={16} height={16} />
              )}
            </div>
          </div>
        );
      }}
    />
  );
}

export default TimezoneHeadlessSelect;
