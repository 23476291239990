export const ONE_ON_ONE_LANDING_PAGE_VIEW_EVENT = '1on1_landing_page_view';
export const ONE_ON_ONE_LANDING_PAGE_BOOKING_DAYS_LEFT_NAV_ARROW_CLICK =
  '1on1_landing_page_booking_days_left_nav_arrow_click';
export const ONE_ON_ONE_LANDING_PAGE_BOOKING_DAYS_RIGHT_NAV_ARROW_CLICK =
  '1on1_landing_page_booking_days_right_nav_arrow_click';
export const ONE_ON_ONE_LANDING_PAGE_BOOKING_DAYS_CHANGE_DATE =
  '1on1_landing_page_booking_days_change_date';
export const ONE_ON_ONE_LANDING_PAGE_BOOKING_DAYS_CHANGE_TIME =
  '1on1_landing_page_booking_days_change_time';
export const ONE_ON_ONE_LANDING_PAGE_BOOKING_CARD_CHANGE_TZ =
  '1on1_landing_page_booking_card_change_tz';
export const ONE_ON_ONE_LANDING_PAGE_BOOKING_CARD_BOOK_BTN_CLICK =
  '1on1_landing_page_booking_card_book_btn_click';
export const ONE_ON_ONE_LANDING_PAGE_JOIN_BTN_CLICK =
  '1on1_landing_page_join_btn_click';
export const ONE_ON_ONE_LANDING_PAGE_VIEW_DATES_CLICK =
  '1on1_landing_page_view_dates_click';
