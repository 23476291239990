import NextImage from '@/components/common/NextImage';
import React from 'react';

const BannerImage = ({ resourceInfo }) => {
  const thumbnailSrc = resourceInfo?.thumbnail;

  return (
    <div className="c-OneOnOneLandingPage-BannerImage">
      <div className="relative aspect-[16/9] overflow-hidden lg:rounded-24">
        <NextImage
          mobileImgProps={{
            src: thumbnailSrc,
            objectFit: 'cover',
            layout: 'fill'
          }}
        />
      </div>
    </div>
  );
};

export default BannerImage;
