import adminProtectedAxiosRequest from '@/services/helpers/adminProtectedAxiosRequest';
import config from '@/utility/config';
import Auth from '@/modules/Auth';
import { getAxiosError, getAxiosErrorMsg } from '@/services/helpers';
// import { convertObjToQueryString } from '@/services/helpers/queryString';
import axios from 'axios';

const { communityApiBasePath } = config;

export const get1on1Session = async (sessionId, communityId) => {
  const { data, error } = await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/communities/${communityId}/${sessionId}`
  );
  if (error) {
    return {
      data: null,
      error: getAxiosErrorMsg(error)
    };
  }
  return { data: data, error: null };
};

export const create1on1Session = async (session, communityId) => {
  const { data, error } = await adminProtectedAxiosRequest.post(
    `${communityApiBasePath}/api/v1/communities/${communityId}/session/create`,
    session
  );
  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
  return { data: data, error: null };
};

export const update1on1Session = async (
  sessionId,
  payload,
  communityId
) => {
  const { data, error } = await adminProtectedAxiosRequest.put(
    `${communityApiBasePath}/api/v1/communities/${communityId}/session/${sessionId}`,
    payload
  );
  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
  return { data: data, error: null };
};

export const get1on1SessionBookings = async ({
  oneOnOneSessionId,
  activeCommunityId,
  params
}) => {
  const authToken = Auth.getUserToken();
  const apiPath = `${communityApiBasePath}/api/v1/communities/${activeCommunityId}/session/${oneOnOneSessionId}/bookings`;
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    params: params
  };

  const { data, error } = await adminProtectedAxiosRequest.get(
    apiPath,
    config
  );

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }

  return { data, error: null };
};

export const cancel1on1Session = async ({
  activeCommunityId,
  oneOnOneSessionId,
  payload
}) => {
  const { data, error } = await adminProtectedAxiosRequest.put(
    `${communityApiBasePath}/api/v1/communities/${activeCommunityId}/session/${oneOnOneSessionId}/cancel-booking`,
    payload
  );
  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
  return { data: data, error: null };
};

export const getHostInfo = async ({
  activeCommunityId,
  hostLearnerObjectId
}) => {
  const { data, error } = await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/communities/${activeCommunityId}/hostInfo/${hostLearnerObjectId}`
  );
  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
  return { data: data, error: null };
};

/**
 * Retrieves the available 1-on-1 session slots for a given session and community.
 * @param {Object} params - The parameters for the API request.
 * @param {string} params.sessionId - The ID of the 1-1 session.
 * @param {string} params.communityId - The ID of the community.
 * @returns {Promise<Object>} The response object containing the slot data and error status.
 */

export const getBookingsCSV = async (params) => {
  const { sessionId, communityId } = params;

  const defaultPayload = {
    type: 'ALL',
    page: '1',
    order: 'asc',
    search: ''
  };

  const { data, error } = await adminProtectedAxiosRequest.get(
    `${communityApiBasePath}/api/v1/communities/${communityId}/session/${sessionId}/bookings-csv`,
    { params: defaultPayload }
  );

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }

  return { data, error: null };
};

/**
 * Retrieves the available 1-on-1 session slots for a given session and community.
 * @param {Object} params - The parameters for the API request.
 * @param {string} params.sessionId - The ID of the 1-1 session.
 * @param {string} params.communityId - The ID of the community.
 * @param {string} params.rangeEnd - The end date of the time range for the slots.
 * @param {string} params.rangeStart - The start date of the time range for the slots.
 * @param {string} params.timezone - The timezone for the slots.
 * @returns {Promise<Object>} The response object containing the slot data and error status.
 */
export const get1on1SessionSlotsAPI = async (params) => {
  try {
    const { sessionId, communityId, rangeEnd, rangeStart, timezone } =
      params;
    const queryParams = { rangeEnd, rangeStart, timezone };
    // const queryParamsStr = convertObjToQueryString(queryParams);
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/session/${sessionId}/slots`;

    const res = await axios.get(apiPath, { params: queryParams });
    return { data: res.data, error: false };
  } catch (e) {
    return getAxiosError(e);
  }
};
