import { t } from '@/utility/localization';
import Icon from '@/components/npl/Icon';

const MobileNoSlotsAvailable = ({ cmName }) => {
  return (
    <div className="c-1on1Session-MobileNoSlotsAvailable z-20 flex w-full justify-between gap-x-24 bg-npl-base-white px-12 py-16">
      <div className="text-label-lg font-medium text-npl-text-icon-on-light-surface-secondary">
        {t('cmname-is-fully-booked-at-the-moment-please-revisit-soon', {
          cmName: cmName
        })}
      </div>
      <div className="flex items-center justify-center">
        <Icon
          name="message-x-square"
          width={32}
          height={32}
          className="fill-npl-text-icon-on-light-surface-tertiary"
        />
      </div>
    </div>
  );
};

export default MobileNoSlotsAvailable;
