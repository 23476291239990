import { t } from '@/utility/localization';
import Icon from '@/components/npl/Icon';

const NoSlotsAvailableCard = ({ cmName }) => {
  return (
    <div className="c-1on1Session-NoSlotsAvailableCard space-y-24 rounded-28 bg-npl-base-white p-32 shadow-npl-styles-shadow-01">
      <Icon
        name="message-x-square"
        width={40}
        height={40}
        className="fill-npl-text-icon-on-light-surface-tertiary"
      />
      <div className="text-label-lg font-medium text-npl-text-icon-on-light-surface-secondary">
        {t('cmname-is-fully-booked-at-the-moment-please-revisit-soon', {
          cmName: cmName
        })}
      </div>
    </div>
  );
};

export default NoSlotsAvailableCard;
