import { protectedAxiosFetcher } from '@/services/helpers/swr';
import config from '@/utility/config';
import useSWR from 'swr';

const { communityApiBasePath } = config;

const useUserUpcoming1on1Booking = ({ isLoggedIn, oneOnOneSessionId }) => {
  const { data, error, mutate } = useSWR(
    () =>
      isLoggedIn
        ? `${communityApiBasePath}/api/v1/communities/session/bookings?sessionId=${oneOnOneSessionId}&status=UPCOMING`
        : null,
    protectedAxiosFetcher
  );

  const upcomingBooking = data?.data?.[0] || null;

  return {
    upcomingBookings: data?.data || [],
    upcomingBooking,
    hasUpcomingBookings: !!upcomingBooking,
    error,
    isLoading: !data && !error,
    refreshUpcomingBookings: mutate
  };
};

export default useUserUpcoming1on1Booking;
