import NextImage from '@/components/common/NextImage';
import React from 'react';

const HostProfileImage = ({ hostInfo }) => {
  const hostImgSrc = hostInfo?.profileImage;

  return (
    <div className="c-OneOnOneLandingPage-HostProfileImage relative h-64">
      <div className="absolute left-24 top-[-64px]">
        <div className="flex h-[128px] w-[128px] items-center justify-center overflow-hidden rounded-full border-4 border-white-default bg-white-default shadow-lg">
          {hostImgSrc && (
            <NextImage
              mobileImgProps={{
                src: hostImgSrc,
                width: 120,
                height: 120,
                objectFit: 'cover',
                priority: true
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HostProfileImage;
