import PropTypes from 'prop-types';
import React from 'react';

const SuccessCheckIcon = ({
  customClassNames = '',
  width = 100,
  height = 100
}) => {
  return (
    <svg
      className={`c-SuccessCheckIcon ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="50" fill="#D2FFEA"></circle>
      <circle cx="49.375" cy="49.375" r="40.625" fill="#99F5CA"></circle>
      <path
        fill="#00C572"
        d="M50 81.25c17.259 0 31.25-13.991 31.25-31.25S67.259 18.75 50 18.75 18.75 32.741 18.75 50 32.741 81.25 50 81.25z"></path>
      <path
        fill="#D2FFEA"
        stroke="#D2FFEA"
        d="M67.722 36.916a3.958 3.958 0 00-5.465 0L44.879 53.498l-7.136-6.809a3.959 3.959 0 00-5.465 5.728l8.804 8.401a5.5 5.5 0 007.593 0l19.047-18.174a3.958 3.958 0 000-5.728z"></path>
    </svg>
  );
};

SuccessCheckIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};

export default SuccessCheckIcon;
