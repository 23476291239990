import NextImage from '@/components/common/NextImage';
import React from 'react';

const HostCard = ({ hostInfo }) => {
  const { profileImage, hostName, hostTitle } = hostInfo || {};

  return (
    <div className="c-OneOnOneSession-HostCard flex space-x-20 rounded-12 bg-white-default p-16 shadow-npl-styles-shadow-01">
      {/* Host Profile Image */}
      <div className="relative h-80 w-80 shrink-0 overflow-hidden rounded-full">
        <NextImage
          mobileImgProps={{
            src: profileImage,
            layout: 'fill',
            objectFit: 'cover'
          }}
        />
      </div>
      {/* Name and title */}
      <div className="flex flex-col justify-center">
        <h4 className="text-label-lg font-semibold text-dark-1b">
          {hostName}
        </h4>
        <p className="mt-4 text-body-sm text-dark-1b text-opacity-65">
          {hostTitle}
        </p>
      </div>
    </div>
  );
};

export default HostCard;
