import React from 'react';
import NextImage from '@/components/common/NextImage';

const BlurredBackground = ({
  bannerImg,
  alt,
  opacityValue = 'opacity-20',
  blurValue = 'blur-2xl', //sm, md, lg, xl, 2xl, 3xl
  customClassName = '',
  blackOverlay = false,
  blackOverlayOpacity = 'bg-opacity-50'
}) => {
  const imageProps = {
    alt: alt ?? bannerImg,
    className: `w-full h-full ${blurValue} ${opacityValue}`,
    mobileImgProps: {
      src: bannerImg,
      quality: 1,
      layout: 'fill',
      height: '100%',
      objectFit: 'cover'
    }
  };

  return (
    <div
      className={`c-BlurredBackground fixed left-0 top-0 z-[-2] h-full w-full ${customClassName}`}>
      <NextImage {...imageProps} />
      {blackOverlay && (
        <div
          className={`absolute left-0 top-0 h-full w-full bg-black ${blackOverlayOpacity}`}
        />
      )}
    </div>
  );
};

export default BlurredBackground;
