import MobileBottomPopup from '@/components/common/MobileBottomPopup';
import Icon from '@/components/npl/Icon';
import {
  format1On1SessionDateWithDayOfWeek,
  getSlotDaysByMonth
} from '@/features/1on1Sessions/utils';
import { t } from '@/utility/localization';
import classNames from 'classnames';

import React from 'react';

const SelectDatePopup = ({
  open,
  selectedDate,
  sessionSlots,
  onDateSelection,
  onClose
}) => {
  const slotDaysByMonth = getSlotDaysByMonth(sessionSlots);

  return (
    <div>
      <MobileBottomPopup open={open} onClose={onClose}>
        <div className="h-full">
          {/* Header */}
          <div className="relative mt-12 flex items-center justify-center border-b-1 border-npl-neutral-light-solid-4 p-10 px-48">
            <h3 className="text-label-lg font-semibold">
              {t('select-a-date')}
            </h3>
            <button
              className="absolute right-12 top-0 flex h-32 w-32 items-center justify-center"
              onClick={onClose}>
              <Icon
                name="x-close"
                width={16}
                height={16}
                fill="#1B1B18A6"
              />
            </button>
          </div>
          {/* Dates */}
          <div className="flex h-full flex-col space-y-16 overflow-scroll px-16 py-24">
            {slotDaysByMonth?.map(({ month, days }) => {
              return (
                <div key={month}>
                  <h4 className="text-label-lg font-medium text-black">
                    {month}
                  </h4>
                  <div className="mt-12 flex flex-col space-y-12">
                    {days?.map((slot, key) => {
                      const { formattedDate, dayOfWeek } =
                        format1On1SessionDateWithDayOfWeek(slot?.date);
                      const isSelected = selectedDate === slot?.date;
                      return (
                        <div
                          key={key}
                          role="button"
                          tabIndex={0}
                          onClick={() => onDateSelection(slot)}
                          className={classNames(
                            'flex-col items-center justify-center space-y-4 rounded-8 border-1 px-24 py-8',
                            {
                              'border-npl-yellow-light-solid-9 bg-npl-yellow-light-solid-3':
                                isSelected,
                              'border-npl-neutral-light-solid-6 bg-white-default':
                                !isSelected
                            }
                          )}>
                          <p className="text-center text-label-sm text-dark-1b text-opacity-65">
                            {dayOfWeek}
                          </p>
                          <p className="whitespace-nowrap text-center text-label-lg font-medium text-black">
                            {formattedDate}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </MobileBottomPopup>
    </div>
  );
};

export default SelectDatePopup;
