import classNames from 'classnames';
import React from 'react';

const MobileBottomPopup = ({ children, open, onClose }) => {
  // <div class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center" :class="{ 'pointer-events-none': !isOpen, 'transform scale-y-100': isOpen, 'transform scale-y-0': !isOpen, 'transition ease-in-out duration-300': true }"></div>
  return (
    <div className="c-MobileBottomPopup">
      {/* Background tint */}
      <div
        className={classNames('fixed inset-0 bg-black opacity-50', {
          hidden: !open
        })}
        onClick={onClose}></div>
      {/* Popup Modal */}
      <div
        className={classNames(
          'fixed bottom-0 max-h-[calc(100vh-80px)] w-full rounded-t-28 bg-white-default pb-32 z-over-intercom',
          {
            'transform transition-all duration-300 ease-in-out': true,
            // 'scale-y-100': open,
            // 'scale-y-0': !open
            'h-full': open,
            'h-0 !p-0': !open
          }
        )}>
        {children}
      </div>
    </div>
  );
};

export default MobileBottomPopup;
