import NPLButton from '@/components/npl/NPLButton';
import { t } from '@/utility/localization';
import React from 'react';

const MobileNextAvailableSessionCard = ({
  onViewDatesClick,
  nextAvailableDateString
}) => {
  return (
    <div
      className="c-1on1SessionLandingPage-MobileNextAvailableSessionCard
        flex items-center justify-between bg-npl-base-white p-16
        ">
      {/* Next available session */}
      <div>
        <p className="text-button-md font-medium text-dark-1b">
          {t('next-available-session')}
        </p>
        <p className="mt-2 text-button-md font-medium text-dark-1b text-opacity-65">
          {nextAvailableDateString}
        </p>
      </div>
      {/* View dates cta */}
      <NPLButton
        hierarchy="accent_primary"
        buttonText={t('view-dates')}
        onClick={onViewDatesClick}
      />
    </div>
  );
};

export default MobileNextAvailableSessionCard;
